import React, { Component } from 'react'
import'bootstrap/dist/css/bootstrap.min.css';

import categoryImage1 from '../img/category/1.jpg';
import categoryImage2 from '../img/category/2.jpg';
import categoryImage3 from '../img/category/3.jpg';
import categoryImage4 from '../img/category/4.jpg';
import categoryImage5 from '../img/category/5.jpg';
import categoryImage6 from '../img/category/6.jpg';
import categoryImage7 from '../img/category/7.jpg';
import categoryImage8 from '../img/category/8.jpg';
import categoryImage9 from '../img/category/9.jpg';
import categoryImage10 from '../img/category/10.jpg';
import categoryImage11 from '../img/category/11.jpg';
import categoryImage12 from '../img/category/12.jpg';
import categoryImage13 from '../img/category/13.jpg';
import categoryImage14 from '../img/category/14.jpg';
import categoryImage15 from '../img/category/15.jpg';
import categoryImage16 from '../img/category/16.jpg';
import RecipeService from "../services/RecipeService";
import userAvatar from "../img/user.png";

class NavigationComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cat: this.props.match.params.cat,
            activeUser: []
        }
    }

    componentDidMount() {
        RecipeService.getActiveUser().then((res) => {
            this.setState({ activeUser: res.data });
        });
    }

    viewCategory(cat) {
        this.props.history.push(`/category/${cat}`);
        this.setState({ cat: cat });
    }

    userprofile(login) {
        this.props.history.push(`/${login}`);
    }

    renderCategories() {
        const categories = [
            "Первые блюда",
            "Вторые блюда",
            "Гарниры",
            "Салаты",
            "Блюда из мяса",
            "Рыба, морепродукты",
            "Консервация",
            "Соусы и маринады",
            "Десерты",
            "Выпечка",
            "Закуски",
            "Напитки",
            "Быстрый завтрак",
            "Блюда в казане",
            "Блюда за 15 минут",
            "Алкогольные напитки"
        ];

        const categoryImages = [
            categoryImage1,
            categoryImage2,
            categoryImage3,
            categoryImage4,
            categoryImage5,
            categoryImage6,
            categoryImage7,
            categoryImage8,
            categoryImage9,
            categoryImage10,
            categoryImage11,
            categoryImage12,
            categoryImage13,
            categoryImage14,
            categoryImage15,
            categoryImage16,
        ];

        const { cat } = this.props.match.params;

        return categories.map((category, index) => {
            const imgPath = categoryImages[index];

            return (
                <div
                    key={index}
                    onClick={() => this.viewCategory(index + 1)}
                    className={`recipe_category ${cat === `${index + 1}` ? "active" : ""}`}
                >
                    <div className="tile">
                        <div className="icon">
                            <div
                                className="circle"
                                style={{ backgroundImage: `url(${imgPath})` }}
                            ></div>
                        </div>
                        <div className="name">{category}</div>
                    </div>
                </div>
            );
        });
    }

    render() {
        return (
            <section className="recipe_navigation_bar">
                <div className="recipe_navigation_bar_inner">

                    <div className="bar_item">
                        <div className="bar_item_title">Меню</div>
                        {this.renderCategories()}
                    </div>

                    <div className="bar_item">
                        <div className="bar_item_title">Топ 10 Авторов</div>
                        {
                            this.state.activeUser.map(
                                active =>
                                    <a href={`/${active.login}`} className="active_user">
                                        <div className="avatar"
                                             style={{backgroundImage: `url('${active.avatar ? `https://api.makelist.ru/avatars/${active.avatar}` : userAvatar}')`}}></div>
                                        <div className="firstname">{active.firstname}</div>
                                        <div className="total">{active.total_recipes}</div>
                                    </a>
                            )
                        }
                    </div>

                </div>
            </section>
        )
    }
}

export default NavigationComponent
