import React, { Component } from 'react'
import RecipeService from '../services/RecipeService'
import CommentService from '../services/CommentService'
import userAvatar from "../img/user.png";

class ViewRecipeComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            recipe: {},
            originalTitle: document.title,
            textComment: "",
            comments: []
        }
    }

    formatDatetime(datetimeString) {
        const datetime = new Date(datetimeString);

        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };

        return new Intl.DateTimeFormat('ru-RU', options).format(datetime);
    }

    componentDidMount() {
        const jwt = localStorage.getItem('token');

        RecipeService.getRecipeById(this.state.id, jwt).then(res => {
            this.setState({ recipe: res.data, comments: res.data.comments });
            this.props.Userinfo(res.data.id, res.data.name, res.data.firstname, res.data.avatar, res.data.reclike, res.data.like, res.data.like_id);
            document.title = this.state.recipe.name;
        });
    }

    componentWillUnmount() {
        document.title = this.state.originalTitle;
    }

    handleChange = (event) => {
        const name = event.target.name;

        if (event.target.tagName.toLowerCase() === 'textarea') {
            const textarea = event.target;

            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;

            window.scrollTo(0, document.body.scrollHeight);

            this.setState({
                [name]: textarea.scrollHeight,
                textComment: textarea.value
            });
        }
    };

    addComment = (e) => {
        e.preventDefault();

        const jwt = localStorage.getItem('token');
        const { textComment, id } = this.state;

        const requestData = {
            textComment,
            id,
            jwt
        };

        CommentService.add(requestData)
            .then((res) => {

                if (res.status === 200) {
                    const newComment = {
                        text: res.data.text,
                        rid: res.data.rid,
                        firstname: res.data.firstname,
                        avatar: res.data.avatar,
                        datetime: new Date().toISOString()
                    };

                    this.setState(prevState => ({
                        comments: [newComment, ...prevState.comments],
                        textComment: ""
                    }));
                } else {
                    console.log("Произошла ошибка:", res.data.message);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    console.log("Ошибка: Страница не найдена (код 404)");
                } else {
                    console.error("Произошла ошибка:", error);
                }
            });
    };

    render() {

        const { comments } = this.state;

        return (
            <section className="recipe_output">
                <div className="output_inner">

                    <div className="viewrecipe">
                        <div className="background"
                             style={{backgroundImage: `url('https://api.makelist.ru/cover/${this.state.recipe.img}')`}}></div>
                        <div className="description">{this.state.recipe.description}</div>

                        <div className="ingredients">
                            <ul className="list4b">
                                {this.state.recipe.ingredient && this.state.recipe.quantity && this.state.recipe.ingredient.split('AND').map((ingredient, index) => (
                                    <li key={index}>
                                        <div className="ingredients__item">
                                            <span className="ingredients__name">{ingredient}</span>
                                            <span className="ingredients__line"></span>
                                            <span
                                                className="ingredients__count">{this.state.recipe.quantity.split('AND')[index]}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="steps">
                            <ul className="list4b">
                                {this.state.recipe.precipe && this.state.recipe.precipe.split('AND').map((precipe, index) => (
                                    <li key={index}>
                                        <div
                                            className={`steps__line ${index === this.state.recipe.precipe.split('AND').length - 1 ? 'last__line' : ''}`}></div>
                                        <span className="steps__text">{precipe}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="comments">
                            {comments && comments.length > 0 ? (
                                comments.map((comment, index) => (
                                    <div key={index} className="comment">
                                        <div className="avatar"
                                             style={{backgroundImage: `url('${comment.avatar ? `https://api.makelist.ru/avatarsr/r${comment.avatar}` : userAvatar}')`}}></div>
                                        <div className="firstname">{comment.firstname}</div>
                                        <div className="text">{comment.text}</div>
                                        <div className="date">{this.formatDatetime(comment.datetime)}</div>
                                    </div>
                                ))
                            ) : (
                                <div className="nocomments">Нет комментариев</div>
                            )}
                        </div>

                        <form onSubmit={this.addComment} className="add_comment">
                            <textarea
                                className="text"
                                name="text"
                                onChange={this.handleChange}
                                value={this.state.textComment}
                                style={{height: this.state.text, overflowY: 'hidden'}}
                            ></textarea>
                            <button className="send"></button>
                        </form>
                    </div>


                </div>
            </section>
        )
    }
}

export default ViewRecipeComponent
