import axios from 'axios';

const RECIPE_API_URL = "https://my-recipes.ru";

class UserService {

    login(login, password) {
        return axios.get(`${RECIPE_API_URL}/login.php`, {
            params: {
                login: login,
                password: password
            }
        });
    }

    register(login, password, firstname, repeatPassword) {
        return axios.get(`${RECIPE_API_URL}/register.php`, {
            params: {
                login: login,
                password: password,
                firstname: firstname,
                repeatPassword: repeatPassword
            }
        });
    }

    validate(jwt) {
        return axios.get(`${RECIPE_API_URL}/validate.php`, {
            params: {
                jwt: jwt
            }
        });
    }
}

export default new UserService()