import React, { Component } from 'react'
import'bootstrap/dist/css/bootstrap.min.css';
import RecipeService from "../services/RecipeService";
import UserService from "../services/UserService";
import userAvatar from "../img/user.png";
import { Link } from 'react-router-dom';
import {withRouter} from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';

class HeaderComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            auth: false,
            authLoad: true,
            login: '',
            password: '',
            firstname: '',
            repeatPassword: '',
            recipes: [],
            users: [],
            sign: false,
            profile: false,
            isSearch: false,
            showSearchResults: false,
            isRegistrationMode: false
        }

        this.timer = null;

        this.COLOR = [
            { colid: '1', colorb: 'rgba(87, 54, 20, 0.98)', colorf: '#fff' },
            { colid: '2', colorb: 'rgba(100, 196, 225, 0.98)', colorf: '#000' },
            { colid: '3', colorb: 'rgba(179, 88, 88, 0.98)', colorf: '#fff' },
            { colid: '4', colorb: 'rgb(143, 213, 146)', colorf: '#000' },
            { colid: '5', colorb: 'rgba(26, 26, 26, 0.98)', colorf: '#fff' },
            { colid: '6', colorb: 'rgba(208, 208, 208, 0.98)', colorf: '#000' },
            { colid: '7', colorb: 'rgba(221, 168, 215, 0.98)', colorf: '#000' },
            { colid: '8', colorb: 'rgba(204, 173, 98, 0.98)', colorf: '#000' }
        ];
    }

    componentDidMount() {
        const storedVariable = localStorage.getItem('token');

        if (storedVariable) {
            this.setState({ authLoad: true });
            UserService.validate(storedVariable).then((res) => {
                this.setState({ authLoad: false });
                this.setState({ auth: true });
                this.setState({ users: [res.data] });
            })
            .catch(error => {
                this.setState({ authLoad: false });
            });
        } else {
            this.setState({ auth: false, authLoad: false });
        }
    }


    componentDidUpdate() {
        if (this.state.showSearchResults) {
            document.body.classList.add("disable-scroll");
        } else {
            document.body.classList.remove("disable-scroll");
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("disable-scroll");
    }

    addRecipe = (e) =>   {
        this.props.history.push(`/recipe/add`);
    }

    viewRecipe(id) {
        const recipeURL = `/recipe/${id}`;
        if (!window.open(recipeURL, '_blank')) {
            this.props.history.push(recipeURL);
        }
    }

    handleSearchInputChange = (event) => {
        const searchText = event.target.value;
        console.log(searchText);
        this.setState({ searchText });

        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.setState({ isSearch: true });

        if (searchText.length >= 2) {
            this.timer = setTimeout(this.performSearch, 1000);
        } else {
            this.setState({ showSearchResults: false });
            this.setState({ isSearch: false });
        }
    };

    performSearch = () => {
        const { searchText } = this.state;
        if (searchText.length >= 2) {
            RecipeService.getRecipeSearch(searchText).then((res) => {
                this.setState({ recipes: res.data, showSearchResults: true, isSearch: false });
            });
        }
    };

    cancelSearch = (e) =>   {
        this.setState({ showSearchResults: false });
        const searchInput = document.querySelector(".searchline_i");
        if (searchInput) {
            searchInput.value = "";
        }
    }

    toggleRegistrationMode = () => {
        this.setState(prevState => ({
            isRegistrationMode: !prevState.isRegistrationMode,
        }));
    };

    signDialog = (e) => {
        this.setState((prevState) => ({
            sign: !prevState.sign
        }));
    }

    profileDialog = (e) => {
        this.setState((prevState) => ({
            profile: !prevState.profile
        }));
    }

    profileExit = (e) => {
        this.profileDialog();
        localStorage.removeItem('token');
        this.setState({ auth: false });
    }

    signin = (e) => {
        e.preventDefault();

        const { login, password } = this.state;
        UserService.login(login, password)
            .then((res) => {
                this.setState({ auth: true });
                this.setState({ users: [res.data] });
                localStorage.setItem('token', res.data.jwt);
                this.signDialog();
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    console.log("Ошибка: Страница не найдена (код 404)");
                } else {
                    console.error("Произошла ошибка:", error);
                }
            });
    }

    signup = (e) => {
        e.preventDefault();

        const { login, password, firstname, repeatPassword } = this.state;
        UserService.register(login, password, firstname, repeatPassword)
            .then((res) => {
                this.toggleRegistrationMode();
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    console.log("Ошибка: Страница не найдена (код 404)");
                } else {
                    console.error("Произошла ошибка:", error);
                }
            });
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    colorbf(color) {
        for (let i = 0; i < this.COLOR.length; i++) {
            if (this.COLOR[i].colid === color) {
                return this.COLOR[i];
            }
        }
        return { colorb: 'transparent', colorf: 'transparent' };
    }

    CountIngredients(ingredients) {
        const ingredientList = ingredients.split('AND');
        const ingredientCount = ingredientList.length;
        return ingredientCount;
    }

    render() {
        const { authLoad, isSearch, showSearchResults } = this.state;

        return (
            <header>
                <div className="recipe_header content">
                    <Link to="/" className="logo"></Link>
                    <div className="panel">

                            <div className="search">
                                <div className="wrapper">
                                    {isSearch ? (
                                        <div id="loading-bar-spinner" className="spinner"><div className="spinner-icon"></div></div>
                                    ) : showSearchResults ? (
                                        <div className="icon-cancel icon" onClick={this.cancelSearch}></div>
                                    ) : (
                                        <div className="icon-search icon"></div>
                                    )}
                                </div>
                                <input type="text" placeholder="Найти рецепт..." className="searchline_i" onChange={this.handleSearchInputChange}/>
                            </div>

                             {this.state.auth ? (<div className="add" onClick={this.addRecipe}></div>) : null}

                        {authLoad ? (
                            <div className="loading">Загрузка...</div>
                        ) : this.state.auth ? (
                            this.state.users.map(
                                user =>
                                <div className="profile" onClick={this.profileDialog}>
                                    <div className="firstname">{user.firstname}</div>
                                    <div className="avatar" style={{backgroundImage: `url('${!user.avatar ? userAvatar : `https://api.makelist.ru/avatarsr/r${user.avatar}`}')`}}></div>
                                </div>
                            )
                        ) : (
                            <div className="auth_reg" onClick={this.signDialog}>Войти</div>
                        )}

                    </div>
                </div>

                {this.state.sign ? (
                    <div className="dialog">
                        <div className="wrapper">
                            <div className="closed" onClick={this.signDialog}></div>
                            <div className="title">{this.state.isRegistrationMode ? 'Регистрация' : 'Вход на сайт'}</div>
                            <form className="up_in" onSubmit={this.state.isRegistrationMode ? this.signup : this.signin}>
                                <input type="text" className="data" name="login" placeholder="Логин" autoComplete="off" value={this.state.login} onChange={this.handleInputChange}/>
                                {this.state.isRegistrationMode && (
                                <>
                                <input type="text" className="data" name="firstname" placeholder="Имя" autoComplete="off" value={this.state.firstname} onChange={this.handleInputChange}/>
                                </>
                                )}
                                <input type="password" className="data" name="password" placeholder="Пароль" autoComplete="off" value={this.state.password} onChange={this.handleInputChange}/>
                                {this.state.isRegistrationMode && (
                                <>
                                <input type="password" className="data" name="repeatPassword" placeholder="Повторите пароль" autoComplete="off" value={this.state.repeatPassword} onChange={this.handleInputChange}/>
                                </>
                                )}
                                <button type="submit" className="data button">
                                    {this.state.isRegistrationMode ? 'Зарегистрироваться' : 'Войти'}
                                </button>
                            </form>
                            <div className="up_in_button" onClick={this.toggleRegistrationMode}>
                                {this.state.isRegistrationMode ? 'Назад' : 'Регистрация'}
                            </div>
                        </div>
                    </div>
                ) : null}

                {this.state.profile ? (
                    <div className="dialog">
                        <div className="wrapper">
                            <div className="closed" onClick={this.profileDialog}></div>
                            <div className="d_profile">
                                <div className="exit" onClick={this.profileExit}>Выход</div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {this.state.showSearchResults ? (
                    <div className="recipe_search_results">
                        <div className="search_result">
                            <Scrollbars style={{scrollbarColor: '#000 #005cbf'}}>
                                {this.state.recipes.length > 0 ? (
                                    this.state.recipes.map(
                                        recipe =>
                                        <div onClick={() => this.viewRecipe(recipe.id)} className="output_block_s" data-recipe-id="1" style={{ backgroundImage: `url('https://api.makelist.ru/cover/${recipe.img}')` }}>
                                            <div className="output_background" style={{ background: `linear-gradient(to left, transparent, ${this.colorbf(recipe.color).colorb} 20em),linear-gradient(to left, transparent, rgb(187 187 187) 13.5em)` }}>
                                                <div className="output_name" style={{ color: this.colorbf(recipe.color).colorf }}>{recipe.name}</div>
                                                <div className="output_stat_info content">
                                                    <div className="output_avatar" style={{ backgroundImage: `url('${recipe.avatar ? `https://api.makelist.ru/avatarsr/r${recipe.avatar}` : userAvatar}')` }}></div>
                                                    <div className="stat_info"><div className="icon-clock stat_info_icon"></div><span>{recipe.time}</span></div>
                                                    <div className="stat_info"><div className="icon-cart stat_info_icon"></div>{this.CountIngredients(recipe.ingredient)}</div>
                                                    <div className="stat_info"><div className="icon-heart stat_info_icon"></div>{recipe.reclike}</div>
                                                    <div className="stat_info"><div className="icon-commenting-o stat_info_icon">{recipe.comm}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className="search_none">Рецепты не найдены</div>
                                )}
                            </Scrollbars>
                        </div>
                    </div>
                ) : null}
            </header>
        )
    }
}

export default withRouter(HeaderComponent);
