import React, { Component } from 'react'
import RecipeService from '../services/RecipeService'
import { viewRecipe } from'../functions/Functions'

import {withRouter} from "react-router-dom";
import userAvatar from '../img/user.png';
import filterImage1 from "../img/filter/eggs.png";
import filterImage2 from "../img/filter/milk.png";
import filterImage3 from "../img/filter/yeast.png";
import filterImage4 from "../img/filter/Cottage.png";
import filterImage5 from "../img/filter/Butter.png";
import filterImage6 from "../img/filter/sausage.png";
import filterImage7 from "../img/filter/bulb.png";
import filterImage8 from "../img/filter/champignon.png";
import filterImage9 from "../img/filter/flour.png";
import filterImage10 from "../img/filter/cheese.png";
import filterImage11 from "../img/filter/Potato.png";
import filterImage12 from "../img/filter/Tomatoes.png";
import filterImage13 from "../img/filter/Sourcream.png";
import filterImage14 from "../img/filter/Mayonnaise.png";
import filterImage15 from "../img/filter/Kefir.png";
class RecipeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            jwt: localStorage.getItem('token'),
            recipes: [],
            page: 1,
            selectedView: 1,
            cat: this.props.match.params.cat,
            activeView: 1,
            isLoading: false,
            isLoadingMore: false,
            hasMoreRecipes: true,
            innerWidthV: '',
            sort: 'id',
            yOffset: 725,
            activeFilterIngredients: []
        }

        this.COLOR = [
            { colid: '1', colorb: 'rgba(87, 54, 20, 0.98)', colorf: '#fff' },
            { colid: '2', colorb: 'rgba(100, 196, 225, 0.98)', colorf: '#000' },
            { colid: '3', colorb: 'rgba(179, 88, 88, 0.98)', colorf: '#fff' },
            { colid: '4', colorb: 'rgb(143, 213, 146)', colorf: '#000' },
            { colid: '5', colorb: 'rgba(26, 26, 26, 0.98)', colorf: '#fff' },
            { colid: '6', colorb: 'rgba(208, 208, 208, 0.98)', colorf: '#000' },
            { colid: '7', colorb: 'rgba(221, 168, 215, 0.98)', colorf: '#000' },
            { colid: '8', colorb: 'rgba(204, 173, 98, 0.98)', colorf: '#000' }
        ];
    }

    handleResize = () => {
        this.onLoad();
        this.setState({ innerWidthV: window.innerWidth });
    };

    componentDidMount() {
        this.setState({ innerWidthV: window.innerWidth });
        this.loadData(this.state.cat);
        window.addEventListener('resize', this.handleResize);
    }

    loadData(catg, sort) {
        this.setState({ isLoading: true });

        if (catg) {
            this.setState({ recipes: [] });

            RecipeService.getRecipe(this.state.jwt, catg, sort, this.state.activeFilterIngredients).then((res) => {
                this.setState({ hasMoreRecipes: true });
                this.setState({ cat: catg });
                this.setState({ recipes: res.data });
                this.setState({ page: 1 });
                this.setState({ isLoading: false });

                const isMobile = window.innerWidth <= 767;
                if (isMobile) {
                    window.scrollBy({ top: this.state.yOffset, behavior: 'smooth' });
                }
                this.onLoad();
            });
        } else {
            this.setState({ recipes: [] });

            RecipeService.getRecipe(this.state.jwt, 'category', sort, this.state.activeFilterIngredients).then((res) => {
                this.setState({ cat: 'category' });
                this.setState({ recipes: res.data });
                this.setState({ isLoading: false });
                this.onLoad();
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.cat !== prevProps.match.params.cat) {
            this.setState({ cat: this.props.match.params.cat }, () => {
                this.loadData(this.state.cat);
                this.setState({ sort: 'id' });
            });
        }
    }

    handleSortChange = (event) => {
        const selectedSort = event.target.value;
        console.log(selectedSort);
        this.setState({ sort: selectedSort }, () => {
            this.loadData(this.state.cat, this.state.sort);
        });
    }

    loadMoreRecipes = () => {
        const { page, isLoadingMore } = this.state;
        const limit = 30;

        if (!isLoadingMore) {
            this.setState({ isLoadingMore: true });

            RecipeService.getRecipe(this.state.jwt, this.state.cat, this.state.sort, this.state.activeFilterIngredients,page + 1, limit)
                .then((res) => {
                    const newRecipes = res.data;

                    if (newRecipes.length > 0) {
                        this.setState((prevState) => ({
                            recipes: [...prevState.recipes, ...newRecipes],
                            page: page + 1,
                            isLoadingMore: false,
                        }));

                        this.onLoad();
                    } else {
                        this.setState({ hasMoreRecipes: false, isLoadingMore: false });
                    }
                });
        }
    };

    view(selectedView) {
        this.setState({ selectedView, activeView: selectedView }, () => {
            this.onLoad();
        });
    }

    onLoad() {
        const blocks = document.querySelectorAll('.recipe_tiles > .output_block');
        const selectedView = this.state.selectedView;

        let widths;

        if (this.state.innerWidthV > 1000) {
            if (selectedView === 1) {
                widths = ['100.5%', '55.5%', '44%', '44%', '55.5%', '47.5%', '52%'];
            } else if (selectedView === 2) {
                widths = ['100%'];
            } else {
                widths = ['50%', '49.5%'];
            }

            for (let i = 0; i < blocks.length; i++) {
                blocks[i].style.width = widths[i % widths.length];
            }
        } else {
            for (let i = 0; i < blocks.length; i++) {
                blocks[i].style.width = '100%';
            }
        }
    }

    colorbf(color) {
        for (let i = 0; i < this.COLOR.length; i++) {
            if (this.COLOR[i].colid === color) {
                return this.COLOR[i];
            }
        }
        return { colorb: 'transparent', colorf: 'transparent' };
    }

    CountIngredients(ingredients) {
        const ingredientList = ingredients.split('AND');
        const ingredientCount = ingredientList.length;
        return ingredientCount;
    }

    filterSelect(ingredient) {
        let activeFilterIngredients = [...this.state.activeFilterIngredients];
        const ingredientExists = activeFilterIngredients.includes(ingredient);
        if (ingredientExists) {
            activeFilterIngredients = activeFilterIngredients.filter(item => item !== ingredient);
        } else {
            activeFilterIngredients.push(ingredient);
        }

        this.setState({ activeFilterIngredients });
        this.setState({ isLoading: true });

        RecipeService.getRecipe(this.state.jwt, this.state.cat, this.state.sort, activeFilterIngredients).then((res) => {
            this.setState({ recipes: res.data, isLoading: false, hasMoreRecipes: true, page: 1 });
            this.onLoad();
        });
    }

    renderFilter() {
        const categories = [
            "Яйца",
            "Молоко",
            "Дрожжи",
            "Творог",
            "Масло",
            "Колбаса",
            "Лук",
            "Шампиньоны",
            "Мука",
            "Сыр",
            "Картофель",
            "Помидоры",
            "Сметана",
            "Майонез",
            "Кефир"
        ];

        const filterImages = [
            filterImage1,
            filterImage2,
            filterImage3,
            filterImage4,
            filterImage5,
            filterImage6,
            filterImage7,
            filterImage8,
            filterImage9,
            filterImage10,
            filterImage11,
            filterImage12,
            filterImage13,
            filterImage14,
            filterImage15
        ];

        const { activeFilterIngredients } = this.state;

        return categories.map((category, index) => {
            const imgPath = filterImages[index];
            const isActive = activeFilterIngredients.includes(category);

            return (
                <div
                    onClick={() => this.filterSelect(category)}
                    key={index}
                    className="ingredient"
                >
                    <div className="tile">
                        <div className="icon">
                            <div
                                className={`circle ${isActive ? "active" : ""}`}
                                style={{ backgroundImage: `url(${imgPath})` }}
                            ></div>
                        </div>
                        <div className="name">{category}</div>
                    </div>
                </div>
            );
        });
    }

    render() {
        const { isLoading, isLoadingMore, hasMoreRecipes } = this.state;

        return (
            <section className="recipe_output">
                <div className="output_inner">

                    <div className="filter">
                        <p>Выберите ингредиент:</p>
                        {this.renderFilter()}
                    </div>

                    <div className="output_inner_panel">
                        <div
                            onClick={() => this.view(1)}
                            className={`button view_1 ${this.state.activeView === 1 ? "active" : ""}`}
                        ></div>
                        <div
                            onClick={() => this.view(2)}
                            className={`button view_2 ${this.state.activeView === 2 ? "active" : ""}`}
                        ></div>
                        <div
                            onClick={() => this.view(3)}
                            className={`button view_3 ${this.state.activeView === 3 ? "active" : ""}`}
                        ></div>
                        <select className="sort" value={this.state.sort} onChange={this.handleSortChange}>
                            <option value="id">Новые</option>
                            <option value="reclike">Популярные</option>
                        </select>
                    </div>

                    <div className="recipe_tiles">
                    {
                        isLoading ? (
                            <span className="loader"></span>
                        ) : (
                            this.state.recipes.length === 0 ? (
                                <div className="none">Рецепты не найдены</div>
                            ) : (
                                <>
                                    {this.state.recipes.map(recipe => (
                                        <div onClick={() => viewRecipe(recipe.id)} className="output_block" style={{ backgroundImage: `url('https://api.makelist.ru/cover/${recipe.img}')` }}>
                                            <div className="output_background" style={{
                                                background: this.state.innerWidthV < 800
                                                    ? `linear-gradient(to left, transparent, ${this.colorbf(recipe.color).colorb} 20em),linear-gradient(to left, transparent, rgb(187 187 187) 13.5em)`
                                                    : `linear-gradient(to left, transparent, ${this.colorbf(recipe.color).colorb} 45em),linear-gradient(to left, transparent, rgb(187 187 187) 21em)`
                                            }}>
                                                <div className="output_name" style={{ color: this.colorbf(recipe.color).colorf }}>{recipe.name}</div>
                                                <div className="output_stat_info content">
                                                    <div className="output_avatar" style={{ backgroundImage: `url('${recipe.avatar ? `https://api.makelist.ru/avatarsr/r${recipe.avatar}` : userAvatar}')` }}></div>
                                                    <div className="stat_info"><div className="icon-clock stat_info_icon"></div><span>{recipe.time}</span></div>
                                                    <div className="stat_info"><div className="icon-cart stat_info_icon"></div>{this.CountIngredients(recipe.ingredient)}</div>
                                                    <div className={`stat_info ${recipe.like ? 'like_b' : ''}`}><div className={`icon-heart stat_info_icon ${recipe.like ? 'like_put' : ''}`}></div>{recipe.reclike}</div>
                                                    <div className="stat_info"><div className="icon-commenting-o stat_info_icon">{recipe.comm}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="loadmore">
                                        {hasMoreRecipes && this.state.recipes.length > 0 ? (
                                            isLoadingMore ? (
                                                <span className="loader"></span>
                                            ) : (
                                                <button onClick={this.loadMoreRecipes} className="button">Показать еще</button>
                                            )
                                        ) : null}
                                    </div>
                                </>
                            )
                        )
                    }
                    </div>
                </div>
            </section>
        )
    }
}
export default withRouter(RecipeComponent);
