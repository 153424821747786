import React, { Component } from 'react'
import RecipeService from '../services/RecipeService'
import {withRouter} from "react-router-dom";
import userAvatar from '../img/user.png';
import { viewRecipe } from'../functions/Functions'
class RecipeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            jwt: localStorage.getItem('token'),
            recipes: [],
            page: 1,
            selectedView: 1,
            id: this.props.id,
            activeView: 1,
            isLoading: false,
            hasMoreRecipes: true,
            innerWidthV: '',
            yOffset: 725
        }

        this.COLOR = [
            { colid: '1', colorb: 'rgba(87, 54, 20, 0.98)', colorf: '#fff' },
            { colid: '2', colorb: 'rgba(100, 196, 225, 0.98)', colorf: '#000' },
            { colid: '3', colorb: 'rgba(179, 88, 88, 0.98)', colorf: '#fff' },
            { colid: '4', colorb: 'rgb(143, 213, 146)', colorf: '#000' },
            { colid: '5', colorb: 'rgba(26, 26, 26, 0.98)', colorf: '#fff' },
            { colid: '6', colorb: 'rgba(208, 208, 208, 0.98)', colorf: '#000' },
            { colid: '7', colorb: 'rgba(221, 168, 215, 0.98)', colorf: '#000' },
            { colid: '8', colorb: 'rgba(204, 173, 98, 0.98)', colorf: '#000' }
        ];
    }

    handleResize = () => {
        this.onLoad();
        this.setState({ innerWidthV: window.innerWidth });
    };

    componentDidMount() {
        this.setState({ innerWidthV: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps) {
        if (this.props.uid && this.props.uid !== prevProps.uid) {
            this.loadData();
        }
    }

    loadData() {
        this.setState({ recipes: [] });
        if (this.props.uid) {
            RecipeService.getRecipeProfile(this.state.jwt, this.props.uid).then((res) => {

                this.setState({hasMoreRecipes: true});
                this.setState({recipes: res.data});
                this.setState({page: 1});

                this.onLoad();
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    loadMoreRecipes = () => {
        const { page, isLoading } = this.state;
        const limit = 30;


        if (!isLoading) {
            this.setState({ isLoading: true });

            RecipeService.getRecipeProfile(this.state.jwt, this.props.uid,page + 1, limit)
                .then((res) => {
                    const newRecipes = res.data;

                    if (newRecipes.length > 0) {
                        this.setState((prevState) => ({
                            recipes: [...prevState.recipes, ...newRecipes],
                            page: page + 1,
                            isLoading: false,
                        }));

                        this.onLoad();
                    } else {
                        this.setState({ hasMoreRecipes: false, isLoading: false });
                    }
                });
        }
    };

    view(selectedView) {
        this.setState({ selectedView, activeView: selectedView }, () => {
            this.onLoad();
        });
    }

    onLoad() {
        const blocks = document.querySelectorAll('.recipe_tiles > .output_block');
        const selectedView = this.state.selectedView;

        let widths;

        if (this.state.innerWidthV > 1000) {
            if (selectedView === 1) {
                widths = ['100.5%', '55.5%', '44%', '44%', '55.5%', '47.5%', '52%'];
            } else if (selectedView === 2) {
                widths = ['100%'];
            } else {
                widths = ['50%', '49.5%'];
            }

            for (let i = 0; i < blocks.length; i++) {
                blocks[i].style.width = widths[i % widths.length];
            }
        } else {
            for (let i = 0; i < blocks.length; i++) {
                blocks[i].style.width = '100%';
            }
        }
    }

    colorbf(color) {
        for (let i = 0; i < this.COLOR.length; i++) {
            if (this.COLOR[i].colid === color) {
                return this.COLOR[i];
            }
        }
        return { colorb: 'transparent', colorf: 'transparent' };
    }

    CountIngredients(ingredients) {
        const ingredientList = ingredients.split('AND');
        const ingredientCount = ingredientList.length;
        return ingredientCount;
    }

    render() {
        const { isLoading, hasMoreRecipes } = this.state;

        if (this.state.recipes.length === 0) {
            return (
                <section className="recipe_output">
                    <div className="output_inner_loader">
                        <span className="loader"></span>
                    </div>
                </section>
            );
        } else {
            return (
                <section className="recipe_output">
                    <div className="output_inner">
                        <div className="output_inner_panel">
                            <div
                                onClick={() => this.view(1)}
                                className={`button view_1 ${this.state.activeView === 1 ? "active" : ""}`}
                            ></div>
                            <div
                                onClick={() => this.view(2)}
                                className={`button view_2 ${this.state.activeView === 2 ? "active" : ""}`}
                            ></div>
                            <div
                                onClick={() => this.view(3)}
                                className={`button view_3 ${this.state.activeView === 3 ? "active" : ""}`}
                            ></div>
                        </div>

                        <div className="recipe_tiles">
                            {
                                this.state.recipes.map(
                                    recipe =>
                                        <div onClick={() => viewRecipe(recipe.id)} className="output_block"
                                             style={{backgroundImage: `url('https://api.makelist.ru/cover/${recipe.img}')`}}>
                                            <div className="output_background" style={{
                                                background: this.state.innerWidthV < 800
                                                    ? `linear-gradient(to left, transparent, ${this.colorbf(recipe.color).colorb} 20em),linear-gradient(to left, transparent, rgb(187 187 187) 13.5em)`
                                                    : `linear-gradient(to left, transparent, ${this.colorbf(recipe.color).colorb} 45em),linear-gradient(to left, transparent, rgb(187 187 187) 21em)`
                                            }}>
                                                <div className="output_name"
                                                     style={{color: this.colorbf(recipe.color).colorf}}>{recipe.name}</div>
                                                <div className="output_stat_info content">
                                                    <div className="output_avatar"
                                                         style={{backgroundImage: `url('${recipe.avatar ? `https://api.makelist.ru/avatarsr/r${recipe.avatar}` : userAvatar}')`}}></div>
                                                    <div className="stat_info">
                                                        <div className="icon-clock stat_info_icon"></div>
                                                        <span>{recipe.time}</span></div>
                                                    <div className="stat_info">
                                                        <div className="icon-cart stat_info_icon"></div>
                                                        {this.CountIngredients(recipe.ingredient)}</div>
                                                    <div className={`stat_info ${recipe.like ? 'like_b' : ''}`}>
                                                        <div
                                                            className={`icon-heart stat_info_icon ${recipe.like ? 'like_put' : ''}`}></div>
                                                        {recipe.reclike}</div>
                                                    <div className="stat_info">
                                                        <div
                                                            className="icon-commenting-o stat_info_icon">{recipe.comm}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                )
                            }
                            <div className="loadmore">
                                {hasMoreRecipes && this.state.recipes.length > 0 ? (
                                    isLoading ? (
                                        <span className="loader"></span>
                                    ) : (
                                        <button onClick={this.loadMoreRecipes} className="button">Показать еще</button>
                                    )
                                ) : null}
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }
}

export default withRouter(RecipeComponent);
