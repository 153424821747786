import axios from 'axios';

const RECIPE_API_URL = "https://my-recipes.ru";

class CommentService {

    add(params) {
        return axios.post(`${RECIPE_API_URL}/add_comment.php`, params);
    }
}

export default new CommentService()