import React, { Component } from 'react'
import {withRouter} from "react-router-dom";
import userAvatar from '../img/Icon.svg';
import RecipeService from "../services/RecipeService";


class AddRecipeComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            coverImage: '',
            ingredients: [],
            psteps: [],
            color: "1",
            name: '',
            description: '',
            time: '',
            ingredient_name: [],
            ingredient_quantity: [],
            pstep_area: [],
            buttonDisabled: true
        }
    }
    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        const { coverImage, name, description, category, time, ingredients, ingredient_name, ingredient_quantity, pstep_area, color, psteps } = this.state;

        const isEmpty = (
            !coverImage ||
            !name ||
            !description ||
            !category ||
            !time ||
            ingredient_name.some(item => !item.text) ||
            ingredient_quantity.some(item => !item.text) ||
            pstep_area.some(item => !item.text) ||
            !color ||
            !psteps.length
        );

        if (isEmpty !== prevState.buttonDisabled) {
            this.setState({ buttonDisabled: isEmpty });
        }

        if (
            coverImage !== prevState.coverImage ||
            name !== prevState.name ||
            description !== prevState.description ||
            category !== prevState.category ||
            time !== prevState.time ||
            ingredients !== prevState.ingredients ||
            (ingredient_name.length > 0 && ingredient_name !== prevState.ingredient_name) ||
            (ingredient_quantity.length > 0 && ingredient_quantity !== prevState.ingredient_quantity) ||
            (pstep_area.length > 0 && pstep_area !== prevState.pstep_area) ||
            color !== prevState.color ||
            psteps !== prevState.psteps
        ) {
            this.props.Addsinfo(coverImage, name, description, category, time, ingredients, ingredient_name, ingredient_quantity, pstep_area, color, psteps);
        }
    }

    addRecipe = (e) => {
        e.preventDefault();

        const jwt = localStorage.getItem('token');
        const { coverImage, name, description, category, time, ingredient_name, ingredient_quantity, pstep_area, color } = this.state;

        const requestData = {
            coverImage,
            name,
            description,
            category,
            time,
            ingredient_name,
            ingredient_quantity,
            pstep_area,
            color,
            jwt
        };

        RecipeService.addRecipe(requestData)
            .then((res) => {
                console.log(res.status);
                if (res.status === 200) {
                    this.props.history.push(`/`);
                    console.log(res.data.message);
                } else {
                    console.log("Произошла ошибка:", res.data.message);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    console.log("Ошибка: Страница не найдена (код 404)");
                } else {
                    console.error("Произошла ошибка:", error);
                }
            });
    };

    handleChange = (event, fieldName = null) => {
        const { name, value, type } = event.target;
        const updatedState = {};

        if (type === 'select-one' || type === 'select-multiple') {
            updatedState[fieldName || name] = value;
        } else if (type === 'text' || type === 'textarea') {
            if (fieldName === 'ingredient_name' || fieldName === 'ingredient_quantity' || fieldName === 'pstep_area') {
                const currentField = this.state[fieldName || name];
                const updatedField = Array.isArray(currentField)
                    ? currentField.map(item => {
                        if (item.id === name) {
                            return { ...item, text: value };
                        }
                        return item;
                    })
                    : [{ id: name, text: value }];

                updatedState[fieldName || name] = updatedField;
            } else {
                updatedState[fieldName || name] = value;
            }
        } else {
            updatedState[fieldName || name] = value;
        }

        this.setState(updatedState);

        if (fieldName === 'pstep_area') {
            const textarea = document.getElementsByName(name)[0];
            if (textarea) {
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            }
        }
    };


    randomString = (length) => {
        var rnd = '';
        while (rnd.length < length)
            rnd += Math.random().toString(36).substring(2);
        return rnd.substring(0, length);
    };

    addIngredient = () => {
        const ingredientId = this.randomString(10);

        const newIngredient = (
            <div className="ingredient-item" key={ingredientId}>
                <input className="ingredient-name" name={`name_${ingredientId}`} type="text" placeholder="Наименование" onChange={(event) => this.handleChange(event, 'ingredient_name')}/>
                <input className="ingredient-quantity" name={`quantity_${ingredientId}`} type="text" placeholder="Кол-во" onChange={(event) => this.handleChange(event, 'ingredient_quantity')}/>
                <button className="ingredient-delete" onClick={() => this.removeIngredient(ingredientId)}></button>
            </div>
        );

        this.setState(prevState => ({
            ingredients: [...prevState.ingredients, newIngredient],
            ingredient_name: [...prevState.ingredient_name, { id: `name_${ingredientId}`, text: '' }],
            ingredient_quantity: [...prevState.ingredient_quantity, { id: `quantity_${ingredientId}`, text: '' }],
        }));
    };

    removeIngredient = ingredientId => {
        const updatedIngredients = this.state.ingredients.filter(
            ingredient => ingredient.key !== ingredientId
        );

        this.setState({
            ingredients: updatedIngredients,
            ingredient_name: this.state.ingredient_name.filter(item => item.id !== `name_${ingredientId}`),
            ingredient_quantity: this.state.ingredient_quantity.filter(item => item.id !== `quantity_${ingredientId}`)
        });
    };

    addPsteps = () => {
        const pstepsId = this.randomString(10);
        const newPsteps = (
            <div className="psteps-item" key={pstepsId}>
            <textarea
                className="psteps-area"
                name={pstepsId}
                onChange={(event) => this.handleChange(event, 'pstep_area')}
            ></textarea>
                <div className="psteps-attach"></div>
                <button className="psteps-delete" onClick={() => this.removePsteps(pstepsId)}></button>
            </div>
        );
        this.setState(prevState => ({
            psteps: [...prevState.psteps, newPsteps],
            pstep_area: [...prevState.pstep_area, { id: pstepsId, text: '' }],
        }));
    };

    removePsteps = pstepsId => {
        const updatedpsteps = this.state.psteps.filter(
            psteps => psteps.key !== pstepsId
        );

        this.setState({
            psteps: updatedpsteps,
            pstep_area: this.state.pstep_area.filter(item => item.id !== pstepsId),
        });
    };

    handleDragOver = (e) => {
        e.preventDefault();
    };

    handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        this.handleFile(file);
    };

    handleCoverClick = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (e) => this.handleFile(e.target.files[0]);
        input.click();
    };

    handleFile = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageUrl = e.target.result;
                this.setState({ coverImage: imageUrl });
            };
            reader.readAsDataURL(file);
        }
    };

    render() {
        return (
            <section className="recipe_add">
                <div className="inner">
                    <form onSubmit={this.addRecipe} encType="multipart/form-data">
                        <input className="adds name" name="name" placeholder="Название" onChange={this.handleChange} />
                        <input className="adds description" name="description" placeholder="Описание" onChange={this.handleChange} />
                        <select className="adds category" name="category" onChange={(event) => this.handleChange(event, 'category')}>
                            <option value="0">Категория</option>
                            <option value="1">Первые блюда</option>
                            <option value="2">Вторые блюда</option>
                            <option value="3">Гарниры</option>
                            <option value="4">Салаты</option>
                            <option value="5">Блюда из мяса</option>
                            <option value="6">Рыба, морепродукты</option>
                            <option value="7">Консервация</option>
                            <option value="8">Соусы и маринады</option>
                            <option value="9">Десерты</option>
                            <option value="10">Выпечка</option>
                            <option value="11">Закуски</option>
                            <option value="12">Напитки</option>
                            <option value="13">Быстрый завтрак</option>
                            <option value="14">Блюда в казане</option>
                            <option value="15">Блюда за 15 минут</option>
                            <option value="16">Алкогольные напитки</option>
                        </select>
                        <select className="adds time" name="time" onChange={this.handleChange}>
                            <option value="0">Время приготовления</option>
                            <option value="5">5 мин.</option>
                            <option value="10">10 мин.</option>
                            <option value="15">15 мин.</option>
                            <option value="20">20 мин.</option>
                            <option value="25">25 мин.</option>
                            <option value="30">30 мин.</option>
                            <option value="35">35 мин.</option>
                            <option value="40">40 мин.</option>
                            <option value="45">45 мин.</option>
                            <option value="50">50 мин.</option>
                            <option value="55">55 мин.</option>
                            <option value="60">60 мин.</option>
                        </select>
                        <select className="adds diet" name="diet">
                            <option value="0">Без диеты</option>
                            <option value="1">Низкоуглеводные / Кето</option>
                            <option value="2">Вегетарианские</option>
                        </select>
                        <div className="adds_block ingredient">
                            <div className="title">Ингредиенты:</div>
                            <div className="list">{this.state.ingredients}</div>
                            <div className="add" onClick={this.addIngredient}></div>
                        </div>
                        <div className="adds_block psteps">
                            <div className="title">Приготовление:</div>
                            <div className="list">{this.state.psteps}</div>
                            <div className="add" onClick={this.addPsteps}></div>
                        </div>
                        <div className="adds_cover cover"
                             onDragOver={this.handleDragOver}
                             onDrop={this.handleDrop}
                             onClick={this.handleCoverClick}>
                            {this.state.coverImage ?
                                <img src={this.state.coverImage} alt="Recipe Cover"/>
                                :
                                <div>Выберите или перетащите фото</div>
                            }
                        </div>
                        <div className="adds_color">
                            {Array.from({ length: 8 }, (_, index) => (
                                <div className="recipe-color-radios" key={`color-${index + 1}`}>
                                    <div className="recipe-color-radios-tile">
                                        <input
                                            type="radio"
                                            id={`color-${index + 1}`}
                                            name="color"
                                            value={`${index + 1}`}
                                            checked={this.state.color === `${index + 1}`}
                                            onChange={this.handleChange}
                                        />
                                        <label htmlFor={`color-${index + 1}`}>
                                    <span>
                                      <img src={userAvatar} alt="Checked Icon" />
                                    </span>
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button disabled={this.state.buttonDisabled} className={`recipe_adds_button ${this.state.buttonDisabled ? 'disabled' : 'active'}`}>Добавить</button>
                    </form>
                </div>
            </section>
        )
    }
}
export default withRouter(AddRecipeComponent);
