import React, { Component } from 'react';
import './App.css';
import './fontello/animation.css';
import './fontello/fontello.css';
import './fontello/fontello-codes.css';
import './fontello/fontello-embedded.css';
import './fontello/fontello-ie7.css';
import './fontello/fontello-ie7-codes.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import NavigationComponent from './components/NavigationComponent';
import RecipeComponent from './components/RecipeComponent';
import AddRecipeComponent from './components/AddRecipeComponent';
import AddSRecipeComponent from './components/AddSRecipeComponent';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import ViewRecipeComponent from './components/ViewRecipeComponent';
import InfoRecipeComponent from './components/InfoRecipeComponent';
import ProfileComponent from './components/ProfileComponent';
import InfoProfileComponent from './components/InfoProfileComponent';
import EditRecipeComponent from "./components/EditRecipeComponent";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                uid: ''
            },
            recipe: {
                id: '',
                name: '',
                firstname: '',
                avatar: '',
                reclike: '',
                like: '',
                like_id: ''
            },
            adds: {
                coverImage: '',
                name: '',
                description: '',
                category: '',
                time: '',
                ingredients: '',
                ingredient_name: '',
                ingredient_quantity: '',
                pstep_area: '',
                color: '',
                psteps: ''
            }
        }
    }

    Userinfo = (id, name, firstname, avatar, reclike, like, like_id) => {
        this.setState({ recipe: { id, name, firstname, avatar, reclike, like, like_id } });
    }

    Addsinfo = (coverImage, name, description, category, time, ingredients, ingredient_name, ingredient_quantity, pstep_area, color, psteps) => {
        this.setState({ adds: { coverImage, name, description, category, time, ingredients, ingredient_name, ingredient_quantity, pstep_area, color, psteps} });
    }

    profiles = (uid) => {
        this.setState({ user: { uid: uid } });
        console.log(this.state.user.uid);
    }

    render() {
        return (
            <div>
                <Router>

                    <main className="my_container content">
                        <HeaderComponent />
                        <Switch>
                            <Route path="/" exact component={NavigationComponent}></Route>
                            <Route path="/recipe" exact component={NavigationComponent}></Route>
                            <Route
                                path="/recipe/add"
                                render={(props) => (
                                    <AddSRecipeComponent
                                        {...props}
                                        coverImage={this.state.adds.coverImage}
                                        name={this.state.adds.name}
                                        description={this.state.adds.description}
                                        category={this.state.adds.category}
                                        time={this.state.adds.time}
                                        ingredients={this.state.adds.ingredients}
                                        ingredient_name={this.state.adds.ingredient_name}
                                        ingredient_quantity={this.state.adds.ingredient_quantity}
                                        pstep_area={this.state.adds.pstep_area}
                                        color={this.state.adds.color}
                                        psteps={this.state.adds.psteps}
                                    />
                                )}
                            />
                            <Route
                                path="/edit/:id"
                                render={(props) => (
                                    <AddSRecipeComponent
                                        {...props}
                                        coverImage={this.state.adds.coverImage}
                                        name={this.state.adds.name}
                                        description={this.state.adds.description}
                                        category={this.state.adds.category}
                                        time={this.state.adds.time}
                                        ingredients={this.state.adds.ingredients}
                                        ingredient_name={this.state.adds.ingredient_name}
                                        ingredient_quantity={this.state.adds.ingredient_quantity}
                                        pstep_area={this.state.adds.pstep_area}
                                        color={this.state.adds.color}
                                        psteps={this.state.adds.psteps}
                                    />
                                )}
                            />
                            <Route
                                path="/recipe/:id"
                                render={(props) => (
                                    <InfoRecipeComponent
                                        {...props}
                                        id={this.state.recipe.id}
                                        name={this.state.recipe.name}
                                        firstname={this.state.recipe.firstname}
                                        avatar={this.state.recipe.avatar}
                                        reclike={this.state.recipe.reclike}
                                        like={this.state.recipe.like}
                                        like_id={this.state.recipe.like_id}
                                    />
                                )}
                            />
                            <Route path="/category/:cat" component={NavigationComponent}></Route>
                            <Route
                                path="/:login"
                                render={(props) => (
                                    <InfoProfileComponent
                                        {...props}
                                        profiles={this.profiles}
                                    />
                                )}
                            />
                        </Switch>

                        <Switch>
                            <Route path="/" exact component={RecipeComponent}></Route>
                            <Route path="/recipe" exact component={RecipeComponent}></Route>
                            <Route
                                path="/recipe/add"
                                render={(props) => (
                                    <AddRecipeComponent
                                        {...props}
                                        Addsinfo={this.Addsinfo}
                                    />
                                )}
                            />
                            <Route
                                path="/edit/:id"
                                render={(props) => (
                                    <EditRecipeComponent
                                        {...props}
                                        Addsinfo={this.Addsinfo}
                                    />
                                )}
                            />
                            <Route
                                path="/recipe/:id"
                                render={(props) => (
                                    <ViewRecipeComponent
                                        {...props}
                                        Userinfo={this.Userinfo}
                                    />
                                )}
                            />
                            <Route path="/category/:cat" component={RecipeComponent}></Route>
                            <Route
                                path="/:login"
                                render={(props) => (
                                    <ProfileComponent
                                        {...props}
                                        uid={this.state.user.uid}
                                    />
                                )}
                            />
                        </Switch>
                        <FooterComponent />
                    </main>
                </Router>
            </div>
        );
    }
}

export default App;
