import axios from 'axios';

const RECIPE_API_URL = "https://my-recipes.ru";

class RecipeService {

    addRecipe(params) {
        return axios.post(`${RECIPE_API_URL}/add_recipe.php`, params);
    }

    updateRecipe(params) {
        return axios.post(`${RECIPE_API_URL}/update_recipe.php`, params);
    }

    getRecipeSearch(line) {
        return axios.get(`${RECIPE_API_URL}/search_recipe.php`, {
            params: {
                line: line
            },
        });
    }

    getRecipe(jwt, cat, sort, ingredients, page, limit) {
        return axios.get(`${RECIPE_API_URL}/get_recipe.php`, {
            params: {
                jwt: jwt,
                cat: cat,
                sort: sort,
                filter_ingredients: ingredients,
                page: page,
                limit: limit
            },
        });
    }

    getRecipeById(id, jwt){
        return axios.get(`${RECIPE_API_URL}/detailed_recipe.php`,
            { params: { id: id, jwt: jwt } });
    }

    like(id, like, like_id, jwt) {
        return axios.get(`${RECIPE_API_URL}/like.php`,
            { params: { id: id, like: like, like_id: like_id, jwt: jwt } });
    }

    getActiveUser() {
        return axios.post(`${RECIPE_API_URL}/get_active.php`);
    }

    getProfile(login) {
        return axios.get(`${RECIPE_API_URL}/get_profile.php`, {
            params: {
                login: login
            },
        });
    }

    getRecipeProfile(jwt, id, page, limit) {
        return axios.get(`${RECIPE_API_URL}/get_recipe_profile.php`, {
            params: {
                jwt: jwt,
                id: id,
                page: page,
                limit: limit
            },
        });
    }
}

export default new RecipeService()