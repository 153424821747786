import React, { Component } from 'react'

class AddSRecipeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {

        return (
            <section className="recipe_navigation_bar">
                <div className="recipe_navigation_bar_inner">
                    <div className="bar_item">
                        <div className="bar_item_title">Новый рецепт:</div>

                            <div className="recipe_adds_item">
                                <div className="title">Название</div>
                                {this.props.name ? (<div className="yes"></div>) : (<div className="no"></div>)}
                            </div>
                            <div className="recipe_adds_item">
                                <div className="title">Описание</div>
                                {this.props.description ? (<div className="yes"></div>) : (<div className="no"></div>)}
                            </div>
                            <div className="recipe_adds_item">
                                <div className="title">Категория</div>
                                {(!this.props.category || this.props.category <= 0) ? (
                                    <div className="no"></div>
                                ) : (
                                    <div className="yes"></div>
                                )}
                            </div>
                            <div className="recipe_adds_item">
                                <div className="title">Время</div>
                                {(!this.props.time || this.props.time <= 0) ? (
                                    <div className="no"></div>
                                ) : (
                                    <div className="yes"></div>
                                )}
                            </div>
                            <div className="recipe_adds_item">
                                <div className="title">Диета</div>
                                <div className="yes"></div>
                            </div>
                            <div className="recipe_adds_item">
                                <div className="title">Ингредиент</div>
                                {this.props.ingredient_name && this.props.ingredient_name.every(item => item.text.trim() !== '') && this.props.ingredients.length !== 0 ? (
                                    <div className="yes"></div>
                                ) : (
                                    <div className="no"></div>
                                )}
                            </div>
                            <div className="recipe_adds_item">
                                <div className="title">Кол-во:</div>
                                {this.props.ingredient_quantity && this.props.ingredient_quantity.every(item => item.text.trim() !== '') && this.props.ingredients.length !== 0 ? (
                                    <div className="yes"></div>
                                ) : (
                                    <div className="no"></div>
                                )}
                            </div>
                            <div className="recipe_adds_item">
                                <div className="title">Приготовление:</div>
                                {this.props.pstep_area && this.props.pstep_area.every(item => item.text.trim() !== '') && this.props.psteps.length !== 0 ? (
                                    <div className="yes"></div>
                                ) : (
                                    <div className="no"></div>
                                )}
                            </div>
                            <div className="recipe_adds_item">
                                <div className="title">Обложка</div>
                                {this.props.coverImage ? (<div className="yes"></div>) : (<div className="no"></div>)}
                            </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AddSRecipeComponent
