import React, { Component } from 'react'
import userAvatar from '../img/user.png';
import RecipeService from "../services/RecipeService";  // Import the image

class InfoRecipeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            s_id: this.props.id,
            s_like: this.props.like,
            s_like_id: this.props.like_id,
            reclike: this.props.reclike,
            likeCountAnimated: false,
            errorMessage: ''
        };
    }

    componentDidUpdate(prevProps) {
        // Обновляем состояние только если значение like изменилось
        if (this.props.like !== prevProps.like) {
            this.setState({
                s_id: this.props.id,
                s_like: this.props.like,
                s_like_id: this.props.like_id,
                reclike: this.props.reclike,
                likeCountAnimated: false
            });
        }
    }

    like = (e) => {
        e.preventDefault();

        const jwt = localStorage.getItem('token');
        const { s_id, s_like, s_like_id } = this.state;

        RecipeService.like(s_id, s_like, s_like_id, jwt)
            .then((res) => {
                this.setState({
                    s_like: res.data.like,
                    s_like_id: res.data.lid,
                    reclike: res.data.reclike,
                    likeCountAnimated: true,
                });

                setTimeout(() => {
                    this.setState({ likeCountAnimated: false });
                }, 1000);

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    this.setState({
                        errorMessage: error.response.data.message
                    });

                    // Скрываем сообщение об ошибке через 2 секунды
                    setTimeout(() => {
                        this.setState({ errorMessage: '' });
                    }, 2000);
                } else if (error.response && error.response.status === 404) {
                    console.log('Ошибка: Страница не найдена (код 404)');
                } else {
                    console.error('Произошла ошибка:', error);
                }
            });
    };

    render() {


        const backgroundImage = this.props.avatar
            ? `url('https://api.makelist.ru/avatarsr/r${this.props.avatar}')`
            : `url(${userAvatar})`;

        return (
            <section className="recipe_navigation_bar">
                <div className="recipe_navigation_bar_inner">
                    <div className="bar_item">
                        <div className="recipe_name">{this.props.name}</div>
                        <div className="recipe_id">№ {this.props.id}</div>
                        <div className="recipe_bar_info">
                            <div className="title">Добавил:</div>
                            <div className="author">
                                <div className="avatar" style={{backgroundImage}}></div>
                                <div className="name">{this.props.firstname}</div>
                            </div>
                        </div>
                        <form onSubmit={this.like}>
                            <div className="recipe_like_block">
                                <button className={`like ${this.state.s_like ? 'like_b' : ''}`}>
                                    <div className={`like_hearth ${this.state.s_like ? 'like_put' : ''}`}>
                                        <div className="icon-heart"></div>
                                    </div>
                                    <div className={`like_count ${this.state.likeCountAnimated ? 'animated' : ''}`}>
                                        {this.state.reclike}
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {this.state.errorMessage && (
                    <div className="error-message-container">
                        <div className="error-message">{this.state.errorMessage}</div>
                    </div>
                )}
            </section>
        )
    }
}

export default InfoRecipeComponent
