import React, { Component } from 'react'
import userAvatar from "../img/user.png";
import RecipeService from "../services/RecipeService";

class InfoProfileComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: '',
            login: this.props.match.params.login,
            firstname: '',
            aboutme: '',
            avatar: ''
        };
    }

    componentDidMount() {
        this.profile(this.state.login);
    }

    profile(login) {
        RecipeService.getProfile(login).then((res) => {
            const { id, firstname, aboutme, avatar } = res.data[0];
            this.setState({ id, firstname, aboutme, avatar });
            this.props.profiles(id);
        });
    }

    render() {

        const backgroundImage = this.state.avatar
            ? `url('https://api.makelist.ru/avatars/${this.state.avatar}')`
            : `url(${userAvatar})`;

        return (
            <section className="recipe_navigation_bar">
                <div className="recipe_navigation_bar_inner">
                    <div className="bar_item">
                        <div className="profile_id"># {this.state.id}</div>
                        <div className="profile_avatar" style={{backgroundImage}}></div>
                        <div className="profile_firstname">{this.state.firstname}</div>
                        <div className="profile_aboutme">{this.state.aboutme}</div>
                    </div>
                </div>
            </section>
        )
    }
}

export default InfoProfileComponent
